.navbar-alignit .navbar-header {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  height: 50px;
}

.navbar-brand {
  padding: 30px !important;
}

.navbar-nav > li > .dropdown-menu {
  z-index: 9999;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: transparent !important;
}

.navbar-inverse {
  background-color: transparent !important;
  border: none !important;
}

.navbar-right li {
  display: inline-block !important;
  border: none !important;
}

.navbar-right li a {
  display: block !important;
  text-decoration: none !important;
  color: #bec9e5 !important;
  font-weight: 800 !important;
  font-size: 12.5px !important;
  text-transform: uppercase !important;
  margin: 15px 35px !important;
}
.navbar-right li a,
.navbar-right li a:after,
.navbar-right li a:before {
  transition: all 0.3s !important;
}
.navbar-right li a:hover {
  color: #55d98a !important;
}
.navbar-right li a {
  position: relative !important;
}
.navbar-right li a:after {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important ;
  margin: auto !important;
  width: 0% !important;
  content: "" !important;
  color: transparent !important;
  background: #55d98a !important;
  height: 1px !important;
}
.navbar-right li a:hover:after {
  width: 100% !important;
}
