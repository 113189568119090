.tabcontent {
  padding: 0px 12px;
  font-family: "Apercu", sans-serif;
  border-left: none;
  height: 300px;
  color: #8893ae;
  width: 60%;
  min-width: 70vh;
  line-height: 1.4;
}

.tabcontent ul li {
  margin-bottom: 2.5%;
}

.tabcontent ul {
  padding: 0 10em;
  list-style: none;
  margin: 0 0 0 29px;
}

.tabcontent li:before {
  content: "";
  border-color: transparent #55d98a;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 0.9em;
  position: relative;
}

.jobTitle {
  color: #ccd7f4;
  font-family: "Apercu", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.dates {
  font-family: "Space Mono", sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding: 7px 0 23px 0;
  color: #a8b3cf;
}
